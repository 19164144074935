@import '../../styles/constants';

html,
body,
#root {
  height: 100%;
}

.app {
  height: 100%;
  // min-height: 100vh;
  outline: none;

  @media screen and (orientation: landscape) {
    overflow-y: auto;
  }

  & > .background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    // background: linear-gradient(202.19deg, $mirage1 2.96%, $mirage2 42.81%);
    background: $mirage2;
  }
}

* {
  box-sizing: border-box;
  font-family: var(--font-family);
}

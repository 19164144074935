@import './constants';

@mixin bottom-buttons {
  position: fixed;
  bottom: 0px;
  left: 50%;
  z-index: 2;
  display: block;
  width: 100%;
  margin: 0 0 0 -50%;
  padding: 20px;
  // background: #141929;
  background: $mirage2;
}

@mixin transform-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin full-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin xs {

  @media screen and (max-width: $xs) {
    @content;
  }
}

@mixin sm {

  @media screen and (min-width: $sm) {
    @content;
  }
}

@mixin md {

  @media screen and (min-width: $md) {
    @content;
  }
}

@mixin lg {

  @media screen and (min-width: $lg) {
    @content;
  }
}

@mixin xl {

  @media screen and (min-width: $xl) {
    @content;
  }
}

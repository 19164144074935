@import '../../styles/constants.scss';

.button {
  padding: 10px 20px;
  color: $defaultColor;
  font-weight: bold;
  font-size: 14px;
  background-color: #d52665;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  &.secondary {
    background: var(--secondary-color);
  }

  &:hover:not(:disabled) {
    color: rgba(255, 255, 255, 0.8);
    filter: brightness(120%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &.grey {
    margin-left: 24px;
    background: $light-grey-color;
  }
}

@import '../../styles/constants.scss';

.attachment {
  &.img {
    width: 100%;
    height: 100%;
  }
}

.img-wrap,
.video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  vertical-align: middle;
}

.full-screen {
  .video-container {
    .attachment-video {
      position: relative;
    }
  }
}

.attachment-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-size: cover;
  border: 0;
}

video::-webkit-media-controls-play-button {
  display: none;
}

video::-webkit-media-controls-volume-slider {
  display: none;
}

video::-webkit-media-controls-mute-button {
  display: none;
}

video::-webkit-media-controls-volume-control-container {
  display: none;
}

.is-circle-frame {
  object-fit: fill;
}

.not-current {
  object-fit: fill;
}

:-webkit-full-screen-ancestor {
  clip-path: none !important;
}

.video-poster {
  width: 100%;
  height: 100%;
}

.game-placeholder {
  position: absolute;
  z-index: 10;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #add8e6;

  &-text {
    text-align: center;
  }

  .start-game-button {
    margin-top: 30px;
  }
}

@keyframes leftBottom {
  from {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
  }

  to {
    -ms-transform: scale(var(--ken-burns-zoom-in));
    -webkit-transform: scale(var(--ken-burns-zoom-in));
    -moz-transform: scale(var(--ken-burns-zoom-in));
    -o-transform: scale(var(--ken-burns-zoom-in));
    transform: scale(var(--ken-burns-zoom-in));
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
  }
}

@keyframes leftTop {
  from {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }

  to {
    -ms-transform: scale(var(--ken-burns-zoom-in));
    -webkit-transform: scale(var(--ken-burns-zoom-in));
    -moz-transform: scale(var(--ken-burns-zoom-in));
    -o-transform: scale(var(--ken-burns-zoom-in));
    transform: scale(var(--ken-burns-zoom-in));
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
}

@keyframes rightTop {
  from {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: top right;
    -moz-transform-origin: top right;
    -ms-transform-origin: top right;
    -o-transform-origin: top right;
    transform-origin: top right;
  }

  to {
    -ms-transform: scale(var(--ken-burns-zoom-in));
    -webkit-transform: scale(var(--ken-burns-zoom-in));
    -moz-transform: scale(var(--ken-burns-zoom-in));
    -o-transform: scale(var(--ken-burns-zoom-in));
    transform: scale(var(--ken-burns-zoom-in));
    -webkit-transform-origin: top right;
    -moz-transform-origin: top right;
    -ms-transform-origin: top right;
    -o-transform-origin: top right;
    transform-origin: top right;
  }
}

@keyframes rightBottom {
  from {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: bottom right;
    -moz-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    -o-transform-origin: bottom right;
    transform-origin: bottom right;
  }

  to {
    -ms-transform: scale(var(--ken-burns-zoom-in));
    -webkit-transform: scale(var(--ken-burns-zoom-in));
    -moz-transform: scale(var(--ken-burns-zoom-in));
    -o-transform: scale(var(--ken-burns-zoom-in));
    transform: scale(var(--ken-burns-zoom-in));
    -webkit-transform-origin: bottom right;
    -moz-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    -o-transform-origin: bottom right;
    transform-origin: bottom right;
  }
}

@keyframes focusZoomIn {
  0% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    -ms-transform: scale(var(--ken-burns-zoom-in));
    -webkit-transform: scale(var(--ken-burns-zoom-in));
    -moz-transform: scale(var(--ken-burns-zoom-in));
    -o-transform: scale(var(--ken-burns-zoom-in));
    transform: scale(var(--ken-burns-zoom-in));
  }
}

@keyframes focusZoomOut {
  0% {
    -ms-transform: scale(var(--ken-burns-zoom-in));
    -webkit-transform: scale(var(--ken-burns-zoom-in));
    -moz-transform: scale(var(--ken-burns-zoom-in));
    -o-transform: scale(var(--ken-burns-zoom-in));
    transform: scale(var(--ken-burns-zoom-in));
  }

  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@import '../../styles/animations';

@import '../../styles/mixins';

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  animation: fade-in 250ms ease-in;
}

.loader {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
}

.loader div {
  position: absolute;
  left: 8px;
  display: inline-block;
  width: 16px;
  background: #fff;
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

.full-loader-container {

  @include full-page;

  background: rgba(0, 0, 0, 0.87);
  backdrop-filter: blur(10px);
  animation: fade-in 150ms ease-in;

  .loader {

    @include transform-center;
  }
}

@import-normalize;

@import './constants';

body {
  margin: 0;
  color: $defaultColor;
  font-family: Roboto, sans-serif;
}

* {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

@font-face {
  font-family: 'Piekos ToonsBB';
  src: local('Piekos ToonsBB'), url(../fonts/PiekosToonsBB.ttf) format('truetype');
}

@font-face {
  font-family: 'Crimson Pro';
  src: local('Crimson Pro'), url(../fonts/CrimsonPro.ttf) format('truetype');
}

@font-face {
  font-family: 'Comic Neue';
  src: local('Comic Neue'), url(../fonts/ComicNeue.ttf) format('truetype');
}

@font-face {
  font-family: 'Cabin';
  src: local('Cabin'), url(../fonts/Cabin.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(../fonts/Oswald.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(../fonts/Ubuntu.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Slab';
  src: local('Roboto Slab'), url(../fonts/RobotoSlab.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Code Pro';
  src: local('Source Code Pro'), url(../fonts/SourceCodePro.ttf) format('truetype');
}

@font-face {
  font-family: 'Bitter';
  src: local('Bitter'), url(../fonts/Bitter.ttf) format('truetype');
}

@font-face {
  font-family: 'Cairo';
  src: local('Cairo'), url(../fonts/Cairo.ttf) format('truetype');
}

@font-face {
  font-family: 'Yanone Kaffeesatz';
  src: local('Yanone Kaffeesatz'), url(../fonts/YanoneKaffeesatz.ttf) format('truetype');
}

@font-face {
  font-family: 'Teko';
  src: local('Teko'), url(../fonts/Teko.ttf) format('truetype');
}

@font-face {
  font-family: 'Rajdhani';
  src: local('Rajdhani'), url(../fonts/Rajdhani.ttf) format('truetype');
}

@font-face {
  font-family: 'Kalam';
  src: local('Kalam'), url(../fonts/Kalam.ttf) format('truetype');
}

@font-face {
  font-family: 'Athelas';
  src: local('Athelas'), url(../fonts/Athelas.ttf) format('truetype');
}

@font-face {
  font-family: 'IvyMode';
  src: local('IvyModeBold'), url(../fonts/IvyModeBold.ttf) format('truetype');
}
